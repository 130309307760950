// /layouts/ChatLayout.js
import React from 'react';
import { Box } from '@mui/material';
import BaseLayout from './BaseLayout';
import Header from '../components/Header'; // Use your default header

// Create a component that returns nothing
const EmptyFooter = () => null;

const ChatLayout = ({ children }) => {
  return (
    <BaseLayout
      Header={() => <Header />}
      Footer={EmptyFooter}  // Pass a valid component that returns null
    >
      <Box sx={{ paddingTop: '80px' /* adjust as needed */ }}>
        {children}
      </Box>
    </BaseLayout>
  );
};

export default ChatLayout;