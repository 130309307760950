// App.js
import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import TopPage from './components/TopPage';
import UserManagement from './components/AdminComponents/UserManagement';
import GuestUserManagement from './components/AdminComponents/GuestUserManagement';
import AccessLog from './components/AdminComponents/AccessLog';
import ServiceManagement from './components/AdminComponents/ServiceManagement';
import TranslationManagement from './components/AdminComponents/TranslationManagement';
import TransactionManagement from './components/AdminComponents/TransactionManagement';
import Signup from './components/Signup';
import Login from './components/Login';
import AccountSettings from './components/AccountSettings';
import SuccessEmailVerification from './components/SuccessEmailVerification';
import ResendEmailVerification from './components/ResendEmailVerification';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import VerifyEmail from './components/VerifyEmail';
import RestoreAccount from './components/RestoreAccount';
import ResendEmailRestore from './components/ResendEmailRestore';
import RemoveBackgroundAI from './components/RemoveBackgroundAI';
import UpScale from './components/UpScale';
import Anime from './components/Anime';
import TextToImageAI from './components/TextToImage';
import Loading from './components/Loading';
import { AuthContext } from './contexts/AuthContext';
import { useCombinedLoading } from './contexts/useCombinedLoading';
import UserLayout from './layouts/UserLayout';
import ChatLayout from './layouts/ChatLayout'; // new chat-specific layout
import AdminLayout from './layouts/AdminLayout';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermOfService';
import ChatStream from './components/ChatStream';
import RagTopPage from './components/RAGComponents/RagTopPage';
import RagChatWindow from './components/RAGComponents/RagChatWindow';

function App() {
  const loading = useCombinedLoading();
  const { isAuthenticated, logout, language, setLanguage } = useContext(AuthContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <Routes>
      {/* Regular User Layout for most routes */}
      <Route 
        path="*" 
        element={
          <UserLayout>
            <Routes>
              <Route path="/" element={<TopPage language={language} />} />
              <Route path="/signup" element={<Signup language={language} />} />
              <Route path="/login" element={<Login language={language} />} />
              <Route path="/account-settings" element={<AccountSettings language={language} />} />
              <Route path="/remove-background-ai" element={<RemoveBackgroundAI language={language} />} />
              <Route path="/image-upscale-ai" element={<UpScale language={language} />} />
              <Route path="/generative-anime-image" element={<Anime language={language} />} />
              <Route path="/text-to-image-generator" element={<TextToImageAI language={language} />} />
              <Route path="/success-email-verification" element={<SuccessEmailVerification language={language} />} />
              <Route path="/resend-verification" element={<ResendEmailVerification language={language} />} />
              <Route path="/forgot-password" element={<ForgotPassword language={language} />} />
              <Route path="/verify/:token" element={<VerifyEmail />} />
              <Route path="/reset-password/:token" element={<ResetPassword language={language} />} />
              <Route path="/restore-account" element={<RestoreAccount language={language} />} />
              <Route path="/resend-restore" element={<ResendEmailRestore language={language} />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy language={language} />} />
              <Route path="/term-of-service" element={<TermsOfService language={language} />} />
              <Route path="/chat" element={<ChatStream language={language} />} />
              <Route path="/rag-system" element={<RagTopPage language={language} />} />
            </Routes>
          </UserLayout>
        }
      />

      {/* Chat Layout for chat routes */}
      <Route
        path="/rag-system/chat/*"
        element={
          <ChatLayout>
            <Routes>
              <Route path=":projectId/:chatId?" element={<RagChatWindow language={language} />} />
            </Routes>
          </ChatLayout>
        }
      />

      {/* Admin Layout */}
      <Route
        path="/admin_panel/*"
        element={
          <ProtectedRoute>
            <AdminLayout
              language={language}
              setLanguage={setLanguage}
              isAuthenticated={isAuthenticated}
              logout={logout}
            >
              <Routes>
                <Route path="" element={<AccessLog />} />
                <Route path="user-management" element={<UserManagement />} />
                <Route path="guest-user-management" element={<GuestUserManagement />} />
                <Route path="service-management" element={<ServiceManagement />} />
                <Route path="translation-management" element={<TranslationManagement />} />
                <Route path="transaction-management" element={<TransactionManagement />} />
              </Routes>
            </AdminLayout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

const ProtectedRoute = ({ children }) => {
  const { role, loading, isAuthenticated } = useContext(AuthContext);
  if (loading) return <Loading />;
  if (!isAuthenticated || role !== 'admin') return <Navigate to="/" replace />;
  return children;
};

export default App;