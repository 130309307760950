import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, IconButton, Collapse, Divider, useMediaQuery } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProjectRow from './ProjectRow';
import ChatRow from './ChatRow';
import SidebarTopBar from './SidebarTopBar';

// Styled components
const SidebarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sidebarWidth'
})(({ theme, sidebarWidth }) => ({
  width: sidebarWidth === 0 ? 20 : sidebarWidth,
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  height: 'calc(100vh - 100px)', // Subtract header height using calc()
  display: 'flex',
  flexDirection: 'column',
}));

const SidebarToggleButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 20,
  height: 40,
  backgroundColor: theme.palette.background.paper,
  border: '1px solid',
  borderColor: theme.palette.divider,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 3,
}));

const SidebarContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));

const Resizer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 5,
  height: '100%',
  zIndex: 2,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));


// Add new styled component for scroll container
const ScrollContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '80vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[400],
    borderRadius: '10px',
    '&:hover': {
      background: theme.palette.grey[500],
    },
  },
}));

/**
 * ChatSidebar
 *
 * Props:
 * - projects: Array of project objects.
 * - expandedProjects: Array of project IDs that are expanded.
 * - toggleProjectExpansion: Function(projId) => void.
 * - chatSessionsByProject: Object mapping projectId to an array of chat objects.
 * - onMenuOpen: Function(e, item) => open the 3-dot menu.
 * - onChatSelect: Function(chatObj) => called when a chat is selected.
 * - onAddChat: Function(projId) => called to add a new chat.
 * - onAddProject: Function() => called to add a new project.
 * - currentChatId: The ID of the currently selected chat.
 * - loadMore: (Optional) Function to load more items.
 * - hasMore: (Optional) Boolean indicating if more items exist.
 */
const ChatSidebar = ({
  projects,
  expandedProjects,
  toggleProjectExpansion,
  chatSessionsByProject,
  onMenuOpen,
  onChatSelect,
  onAddChat,
  onAddProject,
  currentChatId,
  loadMore,
  hasMore,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Sidebar width & resizing state
  const [sidebarWidth, setSidebarWidth] = useState(280);
  const [isResizing, setIsResizing] = useState(false);
  const resizingRef = useRef(false);

  useEffect(() => {
    if (isMobile) {
      setSidebarWidth(0);
    }
  }, [isMobile]);

  useEffect(() => {
    resizingRef.current = isResizing;
  }, [isResizing]);

  const handleResizerMouseMove = useCallback((e) => {
    if (!resizingRef.current) return;
    const newWidth = Math.max(e.clientX, 0);
    setSidebarWidth(newWidth);
  }, []);

  const handleResizerMouseUp = useCallback(() => {
    if (resizingRef.current) {
      setIsResizing(false);
    }
  }, []);

  const handleResizerMouseDown = (e) => {
    setIsResizing(true);
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleResizerMouseMove);
    window.addEventListener('mouseup', handleResizerMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleResizerMouseMove);
      window.removeEventListener('mouseup', handleResizerMouseUp);
    };
  }, [handleResizerMouseMove, handleResizerMouseUp]);

  // Render each project with its chat sessions
  const renderProjects = projects.map((proj) => {
    const pid = String(proj.id);
    const isExpanded = expandedProjects.includes(pid);
    const chats = chatSessionsByProject[pid] || [];
    return (
      <Box key={pid} sx={{ mb: 1 }}>
        <ProjectRow
          project={proj}
          isExpanded={isExpanded}
          onToggleExpand={() => toggleProjectExpansion(pid)}
          onMenuOpen={(e) => onMenuOpen(e, { type: 'project', data: proj })}
          sidebarMode={true}
        />
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          {chats.map((chat) => {
            const chatIdStr = String(chat.id);
            const isSelected = chatIdStr === String(currentChatId);
            return (
              <ChatRow
                key={chatIdStr}
                chat={chat}
                isSelected={isSelected}
                onSelect={() => onChatSelect(chat)}
                onMenuOpen={(e) => onMenuOpen(e, { type: 'chat', data: chat })}
                sidebarMode={true}
              />
            );
          })}
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 6, mt: 1 }}>
            <IconButton color="primary" onClick={() => onAddChat(proj.id)}>
              <AddIcon />
            </IconButton>
            <Typography variant="body2">Add Chat</Typography>
          </Box>
        </Collapse>
        <Divider sx={{ mt: 1 }} />
      </Box>
    );
  });

  const addProjectSection = (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
      <IconButton color="primary" onClick={onAddProject}>
        <AddIcon />
      </IconButton>
      <Typography variant="body2">Add Project</Typography>
    </Box>
  );

  // Optionally use InfiniteScroll if loadMore is provided

  return (
    <SidebarContainer sidebarWidth={sidebarWidth}>
      {sidebarWidth > 0 ? (
        <>
          <SidebarTopBar
            isExpanded={true}
            onBack={() => navigate('/rag-system')}
            onToggle={() => setSidebarWidth(0)}
          />
          <SidebarContent>
            <ScrollContainer id="chatSidebarScrollContainer">
              {loadMore ? (
                <InfiniteScroll
                  dataLength={projects.length}
                  next={loadMore}
                  hasMore={hasMore}
                  scrollableTarget="chatSidebarScrollContainer"
                  loader={
                    <Box sx={{ textAlign: 'center', py: 2 }}>
                      <Typography>Loading...</Typography>
                    </Box>
                  }
                  style={{ overflow: 'hidden' }} // Prevent double scrollbars
                >
                  <Box sx={{ p: 2, pr: 4 }}>
                    {renderProjects}
                    {addProjectSection}
                  </Box>
                </InfiniteScroll>
              ) : (
                <Box sx={{ p: 2, pr: 4 }}>
                  {renderProjects}
                  {addProjectSection}
                </Box>
              )}
            </ScrollContainer>
          </SidebarContent>
          <Resizer onMouseDown={handleResizerMouseDown} />
        </>
      ) : (
        <SidebarToggleButton onClick={() => setSidebarWidth(280)}>
          <ChevronRightIcon />
        </SidebarToggleButton>
      )}
    </SidebarContainer>
  );
}

export default ChatSidebar;
