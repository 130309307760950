import React, { useState } from "react";

const ChatStream = () => {
  const [input, setInput] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null); // ✅ File state

  const fetchAIResponse = async () => {
    setLoading(true);
    setResponse(""); // Clear previous response

    // ✅ Get Token from Local Storage
    const token = localStorage.getItem("token");
    if (!token) {
      alert("You are not authenticated! Please log in.");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("message", input); // ✅ Append message
      if (file) {
        formData.append("file", file); // ✅ Append file if provided
      }

      const res = await fetch("http://localhost:8000/api/rag/projects/72023a18-51ff-4931-94d0-b4dde520f022/chats/ask?chat_id=8e8b2cbe-921d-447c-a9e8-8cbab99b57e0", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}` // ✅ Send token in headers
        },
        body: formData, // ✅ Send as multipart/form-data
      });

      if (!res.body) throw new Error("No response body");

      // ✅ Stream Response
      const reader = res.body.getReader();
      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        setResponse((prev) => prev + decoder.decode(value)); // Append text in real-time
      }
    } catch (error) {
      console.error("Error fetching AI response:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
      <h2>Chat with AI</h2>
      <textarea
        rows="4"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Ask something..."
        style={{ width: "100%", padding: "10px" }}
      />
      <input
        type="file"
        onChange={(e) => setFile(e.target.files[0])} // ✅ Handle file selection
        style={{ marginTop: "10px", display: "block" }}
      />
      <button onClick={fetchAIResponse} disabled={loading} style={{ marginTop: "10px" }}>
        {loading ? "Waiting..." : "Send"}
      </button>
      <div style={{ marginTop: "20px", whiteSpace: "pre-wrap" }}>
        <strong>AI Response:</strong>
        <p>{response}</p>
      </div>
    </div>
  );
};

export default ChatStream;
