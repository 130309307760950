import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, useTheme } from '@mui/material';

export const AddProjectDialog = ({ open, onClose, onSubmit }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const theme = useTheme();

  const handleSubmit = () => {
    onSubmit(name, description);
    setName('');
    setDescription('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ bgcolor: theme.palette.background.paper }}>Add New Project</DialogTitle>
      <DialogContent sx={{ bgcolor: theme.palette.background.paper }}>
        <TextField label="Project Name" fullWidth value={name} onChange={(e) => setName(e.target.value)} margin="dense" />
        <TextField label="Project Description" fullWidth multiline value={description} onChange={(e) => setDescription(e.target.value)} margin="dense" />
      </DialogContent>
      <DialogActions sx={{ bgcolor: theme.palette.background.paper }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddChatDialog = ({ open, onClose, onSubmit }) => {
  const [chatName, setChatName] = useState('');
  const theme = useTheme();

  const handleSubmit = () => {
    onSubmit(chatName);
    setChatName('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ bgcolor: theme.palette.background.paper }}>Add New Chat Session</DialogTitle>
      <DialogContent sx={{ bgcolor: theme.palette.background.paper }}>
        <TextField label="Chat Session Name" fullWidth value={chatName} onChange={(e) => setChatName(e.target.value)} margin="dense" />
      </DialogContent>
      <DialogActions sx={{ bgcolor: theme.palette.background.paper }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">Add</Button>
      </DialogActions>
    </Dialog>
  );
};
