import React, { useState } from 'react';
import { Box, IconButton, Tooltip, alpha, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import LoadingDots from './LoadingDots'; // Import LoadingDots

// ---------------------------
// Updated Styled Components
// ---------------------------
const MessageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginBottom: theme.spacing(2),
  position: 'relative',
}));

const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isUser',
})(({ theme, $isUser }) => ({
  position: 'relative',
  backgroundColor: $isUser
    ? theme.palette.primary.light
    : theme.palette.mode === 'dark'
    ? alpha(theme.palette.grey[700], 0.5)
    : theme.palette.grey[50],
  color: $isUser
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  padding: theme.spacing(0.75),
  paddingLeft: $isUser ? theme.spacing(3) : theme.spacing(2),
  paddingRight: $isUser ? theme.spacing(2) : theme.spacing(3),
  borderRadius: 20,
  maxWidth: '75%',
  width: 'fit-content',
  marginLeft: $isUser ? 'auto' : 0,
  marginRight: $isUser ? 0 : 'auto',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',

  '& .copy-button': {
    position: 'absolute',
    top: theme.spacing(0.5),
    left: $isUser ? theme.spacing(0.5) : 'auto',
    right: $isUser ? 'auto' : theme.spacing(0.5),
    opacity: 0,
    transition: 'opacity 0.3s ease, background-color 0.3s ease',
    color: $isUser 
      ? alpha(theme.palette.primary.contrastText, 0.9)
      : theme.palette.mode === 'dark'
      ? theme.palette.grey[300]
      : theme.palette.grey[700],
    padding: 4,
    borderRadius: 4,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: $isUser
        ? alpha(theme.palette.primary.dark, 0.2)
        : theme.palette.mode === 'dark'
        ? alpha(theme.palette.common.white, 0.1)
        : alpha(theme.palette.common.black, 0.05),
    },
  },

  '&:hover .copy-button': {
    opacity: 1,
  },

  '& pre': {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.black, 0.2)
      : alpha(theme.palette.common.black, 0.03),
    borderRadius: 8,
    overflow: 'auto',
  },

  '& code': {
    fontFamily: 'monospace',
    fontSize: '0.875rem',
    padding: theme.spacing(0.25, 0.5),
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.black, 0.2)
      : alpha(theme.palette.common.black, 0.03),
  },

  '& p': {
    margin: theme.spacing(0.5, 0),
  },
}));

// ---------------------------
// Updated Code Block Component
// ---------------------------
const CodeBlock = ({ inline, className, children, ...props }) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const match = /language-(\w+)/.exec(className || '');
  const language = match ? match[1] : '';

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(String(children).trim());
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (error) {
      console.error('Copy failed', error);
    }
  };

  if (inline) {
    return <code className={className} {...props}>{children}</code>;
  }

  return (
    <Box sx={{ position: 'relative', my: 2 }}>
      <SyntaxHighlighter
        language={language}
        style={okaidia}
        customStyle={{
          padding: '1em',
          borderRadius: '8px',
          overflowX: 'auto',
          fontSize: '0.875rem',
          lineHeight: 1.5,
          margin: 0,
          backgroundColor: theme.palette.mode === 'dark' 
            ? alpha(theme.palette.background.paper, 0.1)
            : alpha(theme.palette.common.black, 0.02),
        }}
        {...props}
      >
        {String(children).trim()}
      </SyntaxHighlighter>
      <Tooltip title={copied ? "Copied!" : "Copy code"}>
        <IconButton
          size="small"
          onClick={handleCopy}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: alpha(theme.palette.background.paper, 0.1),
            color: theme.palette.mode === 'dark' 
              ? theme.palette.grey[300] 
              : theme.palette.grey[700],
            '&:hover': {
              backgroundColor: alpha(theme.palette.background.paper, 0.2),
            },
          }}
        >
          {copied ? <CheckIcon sx={{ fontSize: '0.875rem' }} /> : <ContentCopyIcon sx={{ fontSize: '0.875rem' }} />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

// ---------------------------
// Updated ChatMessage Component with Table Support
// ---------------------------
function ChatMessage({ content, isUser, loading = false }) {
  const theme = useTheme();
  const [copiedMessage, setCopiedMessage] = useState(false);

  // Determine effective loading: Show loading if `loading` is true and content is empty or still streaming.
  const effectiveLoading = loading && (!content || content.trim() === '');

  const handleCopyMessage = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedMessage(true);
      setTimeout(() => setCopiedMessage(false), 1500);
    } catch (error) {
      console.error('Copy message failed', error);
    }
  };

  return (
    <MessageWrapper>
      <MessageBubble $isUser={isUser}>
        {effectiveLoading ? (
          <LoadingDots />
        ) : (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              // Custom code block rendering remains unchanged
              code({ node, inline, className, children, ...props }) {
                return (
                  <CodeBlock inline={inline} className={className} {...props}>
                    {children}
                  </CodeBlock>
                );
              },
              // Add custom table components for a soft, modern look
              table({node, ...props}) {
                return (
                  <Box
                    component="table"
                    sx={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      marginY: 2,
                      border: '1px solid',
                      borderColor: 'divider',
                    }}
                    {...props}
                  />
                );
              },
              thead({node, ...props}) {
                return (
                  <Box
                    component="thead"
                    sx={{
                      backgroundColor: alpha(theme.palette.primary.light, 0.2),
                    }}
                    {...props}
                  />
                );
              },
              tbody({node, ...props}) {
                return <Box component="tbody" {...props} />;
              },
              tr({node, ...props}) {
                return (
                  <Box
                    component="tr"
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                    }}
                    {...props}
                  />
                );
              },
              th({node, ...props}) {
                return (
                  <Box
                    component="th"
                    sx={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      border: '1px solid',
                      borderColor: 'divider',
                    }}
                    {...props}
                  />
                );
              },
              td({node, ...props}) {
                return (
                  <Box
                    component="td"
                    sx={{
                      padding: '8px',
                      textAlign: 'left',
                      border: '1px solid',
                      borderColor: 'divider',
                    }}
                    {...props}
                  />
                );
              },
            }}
          >
            {content}
          </ReactMarkdown>
        )}
        {!effectiveLoading && (
          <Tooltip title={copiedMessage ? "Copied!" : "Copy message"}>
            <IconButton
              className="copy-button"
              size="small"
              onClick={handleCopyMessage}
              sx={{
                minWidth: 28,
                minHeight: 28,
                position: 'absolute',
                top: 8,
                ...(isUser ? { left: 8 } : { right: 8 }),
              }}
            >
              {copiedMessage ? <CheckIcon /> : <ContentCopyIcon />}
            </IconButton>
          </Tooltip>
        )}
      </MessageBubble>
    </MessageWrapper>
  );
}

export default ChatMessage;
