import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Box, Alert, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';

import ChatSidebar from './ChatSidebar';
import ChatConversation from './ChatConversation';
import { AddProjectDialog, AddChatDialog } from './ChatDialogs';
import ManagementMenu from './ManagementMenu';
import RagFileManagementModal from './RagFileManagementModal';
import { createRagApi } from './RAGapi';
import { AuthContext } from '../../contexts/AuthContext';
import InfoDialog from './InfoDialog';

// Main container for the chat window
const RagChatWindowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}));

const ChatArea = styled(Box)(({ theme }) => ({
  flex: 1,
}));

const RagChatWindow = () => {
  const { projectId, chatId } = useParams();
  const navigate = useNavigate();

  const { isAuthenticated } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = React.useMemo(() => createRagApi(apiUrl, isAuthenticated), [apiUrl, isAuthenticated]);

  // Sidebar & project/session states
  const [projects, setProjects] = useState([]);
  const [chatSessionsByProject, setChatSessionsByProject] = useState({});
  const [expandedProjects, setExpandedProjects] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(chatId || null);
  const [currentProjectForChat, setCurrentProjectForChat] = useState(projectId);

  // Modal, error/success, info, and menu states
  const [modalProject, setModalProject] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalItem, setInfoModalItem] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  // Add message timeout ref
  const messageTimeoutRef = useRef(null);

  // Modify display functions to include auto-dismissal
  const clearMessages = () => {
    setError('');
    setSuccess('');
    if (messageTimeoutRef.current) {
      clearTimeout(messageTimeoutRef.current);
      messageTimeoutRef.current = null;
    }
  };

  const displayError = useCallback((msg) => {
    clearMessages();
    setError(msg);
    messageTimeoutRef.current = setTimeout(() => {
      setError('');
    }, 5000); // 5 seconds
  }, []);

  const displaySuccess = (msg) => {
    clearMessages();
    setSuccess(msg);
    messageTimeoutRef.current = setTimeout(() => {
      setSuccess('');
    }, 3000); // 3 seconds
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (messageTimeoutRef.current) {
        clearTimeout(messageTimeoutRef.current);
      }
    };
  }, []);

  const setGlobalMessage = ({ type, text }) => {
    if (type === 'success') displaySuccess(text);
    else if (type === 'error') displayError(text);
  };

  // ---------------- Data Fetching for Sidebar ----------------
  const fetchChatSessions = useCallback(
    async (projId) => {
      try {
        const { data } = await api.getChatSessions(projId);
        setChatSessionsByProject((prev) => ({ ...prev, [projId]: data }));
        // When the project matches and no chat is selected, set a default.
        if (projId === projectId && data.length > 0 && !currentChatId) {
          const defaultChatId = data[0].id;
          setCurrentChatId(defaultChatId);
          navigate(`/rag-system/chat/${projectId}/${defaultChatId}`, { replace: true });
        }
      } catch (err) {
        displayError('Error fetching chat sessions.');
      }
    },
    [api, projectId, currentChatId, navigate, displayError]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await api.getProjects();
        const projectsData = Array.isArray(response.data)
          ? response.data
          : response.data.projects || response.data;
        setProjects(projectsData);

        if (projectId) {
          const pidStr = String(projectId);
          // Merge new project into expandedProjects without collapsing others.
          setExpandedProjects((prev) =>
            prev.includes(pidStr) ? prev : [...prev, pidStr]
          );
          await fetchChatSessions(pidStr);
        }
      } catch (err) {
        displayError('Error fetching projects.');
      }
    })();
  }, [projectId, api, fetchChatSessions, displayError]);

  // Update currentChatId when URL parameter changes.
  useEffect(() => {
    if (chatId) {
      setCurrentChatId(chatId);
    }
  }, [chatId]);

  // ---------------- Management Menu Handlers ----------------
  const handleMenuOpen = (event, item) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedItem(null);
  };

  const handleMenuAction = (action) => {
    if (!selectedItem) return;

    if (action === 'info') {
      if (selectedItem.type === 'chat') {
        const chatProjectId = selectedItem.data.project_id;
        const proj = projects.find((p) => p.id === chatProjectId);
        if (proj) {
          selectedItem.data._projectName = proj.name;
        }
      }
      setInfoModalItem(selectedItem);
      setInfoModalOpen(true);
      handleMenuClose();
      return;
    }

    if (selectedItem.type === 'project') {
      if (action === 'rename') {
        const newName = prompt('Enter new project name', selectedItem.data.name);
        if (newName && newName !== selectedItem.data.name) {
          api.updateProject(selectedItem.data.id, newName, selectedItem.data.description)
            .then(({ data }) => {
              setProjects((prev) =>
                prev.map((p) => (p.id === data.id ? data : p))
              );
              displaySuccess('Project renamed successfully.');
            })
            .catch(() => displayError('Error renaming project.'));
        }
      } else if (action === 'delete') {
        if (window.confirm('Are you sure you want to delete this project?')) {
          api.deleteProject(selectedItem.data.id)
            .then(() => {
              setProjects((prev) =>
                prev.filter((p) => p.id !== selectedItem.data.id)
              );
              displaySuccess('Project deleted successfully.');
            })
            .catch(() => displayError('Error deleting project.'));
        }
      } else if (action === 'file') {
        setModalProject(selectedItem.data.id);
      }
    } else if (selectedItem.type === 'chat') {
      const chatProjectId = selectedItem.data.project_id || currentProjectForChat;
      const chatIdValue = selectedItem.data.chat_id || selectedItem.data.id;
      if (action === 'rename') {
        const newChatName = prompt('Enter new chat session name', selectedItem.data.chat_name);
        if (newChatName && newChatName !== selectedItem.data.chat_name) {
          api.updateProjectChat(chatProjectId, chatIdValue, { chat_name: newChatName })
            .then(({ data }) => {
              setChatSessionsByProject((prev) => ({
                ...prev,
                [chatProjectId]: prev[chatProjectId].map((cs) =>
                  (cs.chat_id || cs.id) === chatIdValue ? data : cs
                ),
              }));
              displaySuccess('Chat session renamed successfully.');
            })
            .catch(() => displayError('Error renaming chat session.'));
        }
      } else if (action === 'delete') {
        if (window.confirm('Are you sure you want to delete this chat session?')) {
          api.deleteProjectChat(chatProjectId, chatIdValue)
            .then(() => {
              setChatSessionsByProject((prev) => ({
                ...prev,
                [chatProjectId]: prev[chatProjectId].filter(
                  (cs) => (cs.chat_id || cs.id) !== chatIdValue
                ),
              }));
              if (chatIdValue === currentChatId) {
                setCurrentChatId(null);
              }
              displaySuccess('Chat session deleted successfully.');
            })
            .catch(() => displayError('Error deleting chat session.'));
        }
      }
    }
    handleMenuClose();
  };

  // ---------------- Sidebar Chat & Project Handlers ----------------
  const handleToggleProjectExpansion = (projIdString) => {
    if (expandedProjects.includes(projIdString)) {
      setExpandedProjects(expandedProjects.filter((id) => id !== projIdString));
    } else {
      setExpandedProjects([...expandedProjects, projIdString]);
      if (!chatSessionsByProject[projIdString]) {
        fetchChatSessions(projIdString);
      }
    }
  };

  const handleChatSelect = (session) => {
    const selChatId = session.chat_id || session.id;
    const selProjId = session.project_id || projectId;
    setCurrentChatId(selChatId);
    setCurrentProjectForChat(selProjId);
    navigate(`/rag-system/chat/${selProjId}/${selChatId}`);
  };

  // ---------------- Add Project & Chat Handlers ----------------
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const handleAddProject = (name, description) => {
    if (name) {
      api.createProject(name, description)
        .then(({ data }) => {
          setProjects((prev) => [...prev, data]);
          displaySuccess('Project added successfully.');
        })
        .catch(() => displayError('Error adding project.'));
    }
    setOpenProjectDialog(false);
  };

  const [openChatDialog, setOpenChatDialog] = useState(false);
  const handleAddChat = (chatName) => {
    if (chatName) {
      api.createChatSession(currentProjectForChat, chatName)
        .then(({ data }) => {
          setChatSessionsByProject((prev) => ({
            ...prev,
            [currentProjectForChat]: prev[currentProjectForChat]
              ? [...prev[currentProjectForChat], data]
              : [data],
          }));
          setCurrentChatId(data.id);
          navigate(`/rag-system/chat/${currentProjectForChat}/${data.id}`);
          displaySuccess('Chat session added successfully.');
        })
        .catch(() => displayError('Error adding chat session.'));
    }
    setOpenChatDialog(false);
  };

  const onAddChat = (projId) => {
    setCurrentProjectForChat(projId);
    navigate(`/rag-system/chat/${projId}`);
    setOpenChatDialog(true);
  };

  return (
    <RagChatWindowContainer>
      <ChatSidebar
        projects={projects}
        expandedProjects={expandedProjects}
        toggleProjectExpansion={handleToggleProjectExpansion}
        chatSessionsByProject={chatSessionsByProject}
        onMenuOpen={handleMenuOpen}
        onChatSelect={handleChatSelect}
        onAddChat={onAddChat}
        onAddProject={() => setOpenProjectDialog(true)}
        currentChatId={currentChatId}
      />

      <ChatArea>
        <Fade in={Boolean(error || success)} timeout={300}>
          <Box sx={{ position: 'sticky', top: 16, zIndex: 1100 }}>
            {error && (
              <Alert 
                severity="error" 
                sx={{ mb: 2 }}
                onClose={clearMessages}
              >
                {error}
              </Alert>
            )}
            {success && (
              <Alert 
                severity="success" 
                sx={{ mb: 2 }}
                onClose={clearMessages}
              >
                {success}
              </Alert>
            )}
          </Box>
        </Fade>

        <ChatConversation currentChatId={currentChatId} projectId={projectId} />
      </ChatArea>

      <ManagementMenu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onAction={handleMenuAction}
        selectedItem={selectedItem}
      />

      {modalProject && (
        <RagFileManagementModal
          open={Boolean(modalProject)}
          onClose={() => setModalProject(null)}
          projectId={modalProject}
          api={api}
          setGlobalMessage={setGlobalMessage}
        />
      )}

      <AddProjectDialog
        open={openProjectDialog}
        onClose={() => setOpenProjectDialog(false)}
        onSubmit={handleAddProject}
      />

      <AddChatDialog
        open={openChatDialog}
        onClose={() => setOpenChatDialog(false)}
        onSubmit={handleAddChat}
      />

      <InfoDialog
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        item={infoModalItem}
      />
    </RagChatWindowContainer>
  );
};

export default RagChatWindow;
