// /components/RAGComponents/RagTopPage.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  Divider,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { ServicesContext } from '../../contexts/ServicesContext';
import { createRagApi } from './RAGapi';
import RagFileManagementModal from './RagFileManagementModal';
import ManagementMenu from './ManagementMenu';
import InfoDialog from './InfoDialog';

// Import your shared row components
import ProjectRow from './ProjectRow';
import ChatRow from './ChatRow';

// Container for the entire page
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  height: '100%',
  overflowY: 'auto',
}));

// Dialog for adding a project
const AddProjectDialog = ({ open, onClose, onCreateProject }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleCreate = () => {
    if (!name.trim()) return;
    onCreateProject(name, description);
    setName('');
    setDescription('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Project Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Project Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RagTopPage = ({ language }) => {
  // Grab contexts
  const { isAuthenticated } = useContext(AuthContext);
  const { services } = useContext(ServicesContext);
  const service = services.find((s) => s.url === '/rag-system');
  const navigate = useNavigate();

  // Set up API
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = React.useMemo(() => createRagApi(apiUrl, isAuthenticated), [apiUrl, isAuthenticated]);

  // Local state
  const [projects, setProjects] = useState([]);
  const [chatSessionsByProject, setChatSessionsByProject] = useState({});
  const [expandedProjects, setExpandedProjects] = useState([]);

  const [globalMessage, setGlobalMessage] = useState(null);

  // For the 3-dot menu
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  // For File Management
  const [modalProject, setModalProject] = useState(null);

  // For creating new project
  const [addProjectOpen, setAddProjectOpen] = useState(false);

  // For "View Info" modal
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalItem, setInfoModalItem] = useState(null);
  
  // On mount, fetch projects
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMessage = (type, text) => {
    setGlobalMessage({ type, text });
  };

  // Fetch Projects
  const fetchProjects = async () => {
    try {
      const { data } = await api.getProjects();
      const projectArr = Array.isArray(data) ? data : data.projects || data;
      setProjects(projectArr);
    } catch (err) {
      showMessage('error', 'Error fetching projects.');
    }
  };

  // Fetch Chats
  const fetchChatsForProject = async (projId) => {
    try {
      const { data } = await api.getChatSessions(projId);
      setChatSessionsByProject((prev) => ({ ...prev, [projId]: data }));
    } catch (err) {
      showMessage('error', 'Error fetching chats for project.');
    }
  };

  // Expand/collapse a project
  const toggleProjectExpansion = async (proj) => {
    const pid = String(proj.id);
    const isExpanded = expandedProjects.includes(pid);

    if (isExpanded) {
      setExpandedProjects(expandedProjects.filter((id) => id !== pid));
    } else {
      setExpandedProjects([...expandedProjects, pid]);
      // Fetch chats if we don't have them
      if (!chatSessionsByProject[pid]) {
        await fetchChatsForProject(proj.id);
      }
    }
  };

  // 3-dot menu
  const handleMenuOpen = (e, item) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
    setSelectedItem(item);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedItem(null);
  };

  // ManagementMenu action
  const handleMenuAction = (action) => {
    if (!selectedItem) return;

    if (action === 'info') {
      if (selectedItem.type === 'chat') {
        const chatProjectId = selectedItem.data.project_id;
        const proj = projects.find((p) => p.id === chatProjectId);
        if (proj) {
          selectedItem.data._projectName = proj.name;
        }
      }
      setInfoModalItem(selectedItem);
      setInfoModalOpen(true);
      handleMenuClose();
      return;
    }

    if (selectedItem.type === 'project') {
      handleProjectMenuAction(action, selectedItem.data);
    } else if (selectedItem.type === 'chat') {
      handleChatMenuAction(action, selectedItem.data);
    }
    handleMenuClose();
  };

  const handleProjectMenuAction = (action, project) => {
    if (action === 'rename') {
      const newName = prompt('Enter new project name', project.name);
      if (newName && newName !== project.name) {
        api
          .updateProject(project.id, newName, project.description)
          .then(({ data }) => {
            setProjects((prev) => prev.map((p) => (p.id === data.id ? data : p)));
            showMessage('success', 'Project renamed successfully.');
          })
          .catch(() => showMessage('error', 'Error renaming project.'));
      }
    } else if (action === 'delete') {
      if (window.confirm(`Are you sure you want to delete project "${project.name}"?`)) {
        api
          .deleteProject(project.id)
          .then(() => {
            setProjects((prev) => prev.filter((p) => p.id !== project.id));
            showMessage('success', 'Project deleted successfully.');
          })
          .catch(() => showMessage('error', 'Error deleting project.'));
      }
    } else if (action === 'file') {
      setModalProject(project.id);
    }
  };

  const handleChatMenuAction = (action, chat) => {
    const projId = chat.project_id;
    if (action === 'rename') {
      const newChatName = prompt('Enter new chat name', chat.chat_name);
      if (newChatName && newChatName !== chat.chat_name) {
        api
          .updateProjectChat(projId, chat.id, { chat_name: newChatName })
          .then(({ data }) => {
            setChatSessionsByProject((prev) => ({
              ...prev,
              [projId]: prev[projId].map((c) => (c.id === chat.id ? data : c)),
            }));
            showMessage('success', 'Chat renamed successfully.');
          })
          .catch(() => showMessage('error', 'Error renaming chat.'));
      }
    } else if (action === 'delete') {
      if (window.confirm(`Are you sure you want to delete chat "${chat.chat_name}"?`)) {
        api
          .deleteProjectChat(projId, chat.id)
          .then(() => {
            setChatSessionsByProject((prev) => ({
              ...prev,
              [projId]: prev[projId].filter((c) => c.id !== chat.id),
            }));
            showMessage('success', 'Chat deleted successfully.');
          })
          .catch(() => showMessage('error', 'Error deleting chat.'));
      }
    }
  };

  // Clicking a chat => navigate to RagChatWindow
  const handleChatSelect = (chat) => {
    navigate(`/rag-system/chat/${chat.project_id}/${chat.id}`);
  };

  // Create project
  const handleCreateProject = async (name, desc) => {
    try {
      const { data } = await api.createProject(name, desc);
      setProjects((prev) => [...prev, data]);
      showMessage('success', 'Project created successfully.');
    } catch {
      showMessage('error', 'Error creating project.');
    }
    setAddProjectOpen(false);
  };

  // Add new chat
  const handleAddChat = async (projId) => {
    const chatName = prompt('Enter new chat name');
    if (!chatName) return;
    try {
      const { data } = await api.createChatSession(projId, chatName);
      setChatSessionsByProject((prev) => ({
        ...prev,
        [projId]: prev[projId] ? [...prev[projId], data] : [data],
      }));
      showMessage('success', 'Chat created successfully.');
    } catch {
      showMessage('error', 'Error creating chat');
    }
  };

  return (
    <Container>
      {service && (
        <>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '1.5rem', sm: '2rem' }, fontWeight: 'bold' }}
            gutterBottom
          >
            {service?.title?.[language]}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
            gutterBottom
          >
            {service?.description?.[language]}
          </Typography>
        </>
      )}

      {globalMessage && (
        <Alert severity={globalMessage.type} sx={{ mb: 2 }}>
          {globalMessage.text}
        </Alert>
      )}

      {/* Add Project button */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => setAddProjectOpen(true)}>
          <AddIcon />
        </IconButton>
        <Typography variant="body2">Add Project</Typography>
      </Box>

      {/* Projects list */}
      {projects.map((proj) => {
        const pid = String(proj.id);
        const isExpanded = expandedProjects.includes(pid);
        const chats = chatSessionsByProject[pid] || [];

        return (
          <Box key={proj.id} sx={{ mb: 1 }}>
            {/* 
              Reuse ProjectRow to keep the same style 
              used in ChatSidebar. 
              We pass isExpanded to show arrow, 
              but you can also omit these icons if you prefer 
            */}
            <ProjectRow
              project={proj}
              isExpanded={isExpanded}
              onToggleExpand={() => toggleProjectExpansion(proj)}
              onMenuOpen={(e) => handleMenuOpen(e, { type: 'project', data: proj })}
            />

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box sx={{ ml: 6, mb: 1 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                  {proj.description || '(No description)'}
                </Typography>
              </Box>

              {/* Chat items using ChatRow */}
              {chats.map((chat) => (
                <ChatRow
                  key={chat.id}
                  chat={chat}
                  // We do not track "selected" here, so pass false or omit 
                  isSelected={false}
                  onSelect={() => handleChatSelect(chat)}
                  onMenuOpen={(e) => handleMenuOpen(e, { type: 'chat', data: chat })}
                />
              ))}

              {/* Add Chat Button */}
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 8, mt: 1 }}>
                <IconButton color="primary" onClick={() => handleAddChat(proj.id)}>
                  <AddIcon />
                </IconButton>
                <Typography variant="body2">Add Chat</Typography>
              </Box>
            </Collapse>

            <Divider sx={{ mt: 1 }} />
          </Box>
        );
      })}

      {/* Management Menu */}
      <ManagementMenu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onAction={handleMenuAction}
        selectedItem={selectedItem}
      />

      {/* File Management Modal */}
      {modalProject && (
        <RagFileManagementModal
          open={Boolean(modalProject)}
          onClose={() => setModalProject(null)}
          projectId={modalProject}
          api={api}
          setGlobalMessage={(msg) => setGlobalMessage(msg)}
        />
      )}

      {/* Add Project Dialog */}
      <AddProjectDialog
        open={addProjectOpen}
        onClose={() => setAddProjectOpen(false)}
        onCreateProject={handleCreateProject}
      />

      {/* Info Dialog (View Info) */}
      <InfoDialog
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        item={infoModalItem}
      />
    </Container>
  );
};

export default RagTopPage;
