import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfiniteScroll from 'react-infinite-scroll-component';

const ScrollableBox = styled(Box)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[400],
    borderRadius: '10px',
    '&:hover': {
      background: theme.palette.grey[500],
    },
  },
}));

// New styled component for selected files with consistent scrollbar style
const ScrollableSelectedFilesBox = styled(Box)(({ theme }) => ({
  maxHeight: '150px',
  overflowY: 'auto',
  marginTop: theme.spacing(1),
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[400],
    borderRadius: '10px',
    '&:hover': {
      background: theme.palette.grey[500],
    },
  },
}));

const RagFileManagementModal = ({ open, onClose, projectId, api, setGlobalMessage }) => {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [localError, setLocalError] = useState('');
  const [localSuccess, setLocalSuccess] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;

  const fetchFiles = useCallback(async (pageNum = 1) => {
    try {
      const { data, total } = await api.pollFileStatus(projectId, pageNum, pageSize);
      if (pageNum === 1) {
        setFiles(data);
      } else {
        setFiles(prev => [...prev, ...data]);
      }
      setHasMore(files.length < total);
    } catch (err) {
      setLocalError('Error fetching file statuses.');
    }
  }, [api, projectId, files.length]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchFiles(nextPage);
  };

  useEffect(() => {
    if (projectId && open) {
      setPage(1);
      setHasMore(true);
      fetchFiles(1);
      const interval = setInterval(() => fetchFiles(1), 1000);
      return () => clearInterval(interval);
    }
  }, [projectId, open, fetchFiles]);

  const handleMultipleUpload = async () => {
    if (selectedFiles.length === 0) return;
    try {
      await api.uploadMultipleFiles(projectId, Array.from(selectedFiles));
      setLocalSuccess('Files uploaded successfully.');
      setSelectedFiles([]);
      fetchFiles(1);
      setGlobalMessage({ type: 'success', text: 'Files uploaded successfully.' });
    } catch (err) {
      setLocalError('Error uploading files.');
      setGlobalMessage({ type: 'error', text: 'Error uploading files.' });
    }
  };

  const handleDeleteFile = async (fileId) => {
    try {
      await api.deleteFile(projectId, fileId);
      fetchFiles(1);
      setGlobalMessage({ type: 'success', text: 'File deleted successfully.' });
    } catch (err) {
      setLocalError('Error deleting file.');
      setGlobalMessage({ type: 'error', text: 'Error deleting file.' });
    }
  };

  const renderStatusIcon = (status) => {
    if (status === 'done') return <CheckCircleIcon color="success" />;
    if (status === 'failed') return <ErrorIcon color="error" />;
    if (status === 'processing') return <CircularProgress size={20} color="inherit" />;
    return null;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: 300, md: 400 },
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 2,
        boxShadow: 24,
        maxHeight: '90vh',
      }}>
        <Typography variant="h6" gutterBottom>
          File Management
        </Typography>
        {localError && <Alert severity="error" sx={{ mb: 2 }}>{localError}</Alert>}
        {localSuccess && <Alert severity="success" sx={{ mb: 2 }}>{localSuccess}</Alert>}
        
        <Box sx={{ mb: 2 }}>
          <Button variant="outlined" component="label">
            Select Files
            <input type="file" multiple hidden onChange={(e) => setSelectedFiles(e.target.files)} />
          </Button>
          {selectedFiles.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle2">Selected Files:</Typography>
              <ScrollableSelectedFilesBox>
                <List dense>
                  {Array.from(selectedFiles).map((file, idx) => (
                    <ListItem key={idx}>
                      <ListItemText primary={file.name} />
                    </ListItem>
                  ))}
                </List>
              </ScrollableSelectedFilesBox>
              <Button variant="contained" sx={{ mt: 1 }} onClick={handleMultipleUpload}>
                Upload Selected
              </Button>
            </Box>
          )}
        </Box>
        
        <Divider sx={{ my: 2 }} />
        
        <Box>
          <Typography variant="subtitle1" gutterBottom>Existing Files:</Typography>
          <ScrollableBox id="fileScrollableDiv">
            {files.length === 0 ? (
              <Typography variant="body2">No files found.</Typography>
            ) : (
              <InfiniteScroll
                dataLength={files.length}
                next={loadMore}
                hasMore={hasMore}
                loader={
                  <Box sx={{ textAlign: 'center', py: 1 }}>
                    <CircularProgress size={20} />
                  </Box>
                }
                scrollableTarget="fileScrollableDiv"
                style={{ overflow: 'visible' }}
              >
                {files.map((file) => (
                  <Box
                    key={file.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      my: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        {renderStatusIcon(file.status)}
                      </ListItemIcon>
                      <Box>
                        <Typography variant="body2">{file.filename}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {new Date(file.created_at).toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                    <Button 
                      variant="text" 
                      color="error" 
                      onClick={() => handleDeleteFile(file.id)}
                    >
                      Delete
                    </Button>
                  </Box>
                ))}
              </InfiniteScroll>
            )}
          </ScrollableBox>
        </Box>
      </Box>
    </Modal>
  );
};

export default RagFileManagementModal;