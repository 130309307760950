// /components/RAGComponents/SidebarTopBar.js

import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

/**
 * SidebarTopBar
 * 
 * Props:
 * - isExpanded (bool): If true, show "← Back" and a collapse button. 
 * - onBack (func): Called when the back button is clicked.
 * - onToggle (func): Called to collapse/expand the sidebar.
 */
const SidebarTopBar = ({ isExpanded, onBack, onToggle }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 0.5,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        zIndex: 3,
      }}
    >
      {isExpanded && (
        <IconButton onClick={onBack}>
          <Typography variant="button">← Back</Typography>
        </IconButton>
      )}
      <IconButton onClick={onToggle}>
        {isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </Box>
  );
};

export default SidebarTopBar;
