// /components/RAGComponents/InfoDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
// Using date-fns for formatting
import { format } from 'date-fns';

const formatDate = (isoString) => {
  if (!isoString) return '(No date)';
  try {
    // Convert ISO date string to YYYY-MM-DD HH:MM:SS
    return format(new Date(isoString), 'yyyy-MM-dd HH:mm:ss');
  } catch {
    // Fallback if parsing fails
    return isoString;
  }
};

const InfoDialog = ({ open, onClose, item }) => {
  if (!item) return null;

  const { data } = item; // The actual project or chat object
  const isProject = item.type === 'project';
  const isChat = item.type === 'chat';

  // The displayed name
  const name = isProject ? data.name : data.chat_name;
  // The displayed description (only for projects)
  const description = isProject ? data.description : '';
  // Format created_at
  const createdAt = formatDate(data.created_at);
  // For chat, we assume you store the project’s name in `data._projectName`.
  // If you haven’t stored it, we fallback to data.project_id.
  const parentProjectName = data._projectName || data.project_id;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {isProject ? 'Project Info' : 'Chat Info'}
      </DialogTitle>

      <DialogContent dividers>
        {isProject && (
          <>
            <Typography variant="h6">Project Name</Typography>
            <Typography variant="body1" gutterBottom>
              {name}
            </Typography>

            <Typography variant="h6">Description</Typography>
            <Typography variant="body1" gutterBottom>
              {description || '(No description)'}
            </Typography>

            <Typography variant="h6">Created At</Typography>
            <Typography variant="body2" gutterBottom>
              {createdAt}
            </Typography>
          </>
        )}

        {isChat && (
          <>
            <Typography variant="h6">Chat Name</Typography>
            <Typography variant="body1" gutterBottom>
              {name}
            </Typography>

            <Typography variant="h6">Parent Project</Typography>
            <Typography variant="body1" gutterBottom>
              {parentProjectName}
            </Typography>

            <Typography variant="h6">Created At</Typography>
            <Typography variant="body2" gutterBottom>
              {createdAt}
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
