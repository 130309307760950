import React from 'react';
import { Box } from '@mui/material';

const LoadingDots = () => {
    return (
        <Box sx={{
            display: 'inline-flex',
            gap: '4px',
            fontSize: '18px',
            fontWeight: 'bold',
            animation: 'blink 1.5s infinite ease-in-out',
        }}>
            {[...Array(3)].map((_, i) => (
                <Box
                    key={i}
                    sx={{
                        animation: 'bounce 1.5s infinite ease-in-out',
                        animationDelay: `${i * 0.2}s`,
                        opacity: 0,
                        color: 'gray',
                        '@keyframes bounce': {
                            '0%, 80%, 100%': { opacity: 0 },
                            '40%': { opacity: 1 },
                        }
                    }}
                >
                    .
                </Box>
            ))}
        </Box>
    );
};

export default LoadingDots;
