// /components/RAGComponents/ProjectRow.js

import React from 'react';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * Styled container for a project row.
 */
const ProjectRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

/**
 * We let fontSize depend on the `sidebarMode` prop:
 * - If `sidebarMode` is `true`, we use 0.875rem (14px).
 * - Otherwise, we keep your default body text at 1rem (16px).
 */
const ProjectNameText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'sidebarMode',
})(({ theme, sidebarMode }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: 0,
  flex: 1,
  fontSize: sidebarMode ? '0.875rem' : '1rem',
}));

const ProjectRow = ({
  project,
  isExpanded,
  onToggleExpand,
  onMenuOpen,
  sidebarMode = false, // new prop
}) => {
  return (
    <ProjectRowContainer onClick={onToggleExpand}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onMenuOpen(e);
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0, flex: 1 }}>
        <FolderIcon sx={{ mr: 1 }} />
        <ProjectNameText sidebarMode={sidebarMode}>
          {project.name}
        </ProjectNameText>
      </Box>

      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </ProjectRowContainer>
  );
};

export default ProjectRow;
