// /components/RAGComponents/ManagementMenu.js
import React from 'react';
import { Menu, MenuItem } from '@mui/material';

const ManagementMenu = ({ anchorEl, open, onClose, onAction, selectedItem }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem onClick={() => onAction('info')}>View Info</MenuItem> {/* <-- New */}
      <MenuItem onClick={() => onAction('rename')}>Rename</MenuItem>
      <MenuItem onClick={() => onAction('delete')}>Delete</MenuItem>
      {selectedItem?.type === 'project' && (
        <MenuItem onClick={() => onAction('file')}>File Management</MenuItem>
      )}
    </Menu>
  );
};

export default ManagementMenu;
