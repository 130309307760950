import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Tooltip, Alert, TableSortLabel
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import { ModalWindow } from '../ReusableComponents';
import ContentEditor from './ContentEditor';

const ServiceManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [services, setServices] = useState([]);
  const [editService, setEditService] = useState(null);
  const [deleteServiceId, setDeleteServiceId] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isAddConfirmModalOpen, setAddConfirmModalOpen] = useState(false);
  const [isEditConfirmModalOpen, setEditConfirmModalOpen] = useState(false);
  const [isContentModalOpen, setContentModalOpen] = useState(false);
  const [isContentEditMode, setContentEditMode] = useState(false);
  const [currentContent, setCurrentContent] = useState([]);
  const [isContentConfirmModalOpen, setContentConfirmModalOpen] = useState(false);

  const [newService, setNewService] = useState({
    service_name: '', title_ja: '', description_ja: '', title_en: '',
    description_en: '', rating: 0, use_count: 0, price: 0, url: '',
    content: [], created_at: '', updated_at: '', service_type: '' 
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ sortBy: 'service_name', sortOrder: 'asc' });

  const getToken = () => localStorage.getItem('token');

  const fetchServices = useCallback((search = '', sortBy = 'service_name', sortOrder = 'asc') => {
    const token = getToken();
    axios.get(`${apiUrl}/data/services_admin/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { search, sort_by: sortBy, sort_order: sortOrder }
    })
    .then(response => setServices(response.data.services))
    .catch(error => console.error("Error fetching services:", error));
  }, [apiUrl]);

  useEffect(() => {
    fetchServices();
  }, [apiUrl, fetchServices]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    fetchServices(e.target.value, sortConfig.sortBy, sortConfig.sortOrder);
  };

  const handleSort = (column) => {
    const isAsc = sortConfig.sortBy === column && sortConfig.sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortConfig({ sortBy: column, sortOrder: newSortOrder });
    fetchServices(searchQuery, column, newSortOrder);
  };

  const handleEdit = (service) => {
    setEditService(service);
    setEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
  
    setEditService((prevService) => ({
      ...prevService,
      [name]: value
    }));
  };

  const validateService = (service) => {
    const errors = {};
    const urlPattern = /^\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)*$/;

    if (!service.service_name) errors.service_name = 'Service name is required';
    if (!service.service_type) errors.service_type = 'Service type is required'; 
    if (!urlPattern.test(service.url)) errors.url = 'URL must match /something or /something/something';
    if (!Number.isInteger(Number(service.price))) errors.price = 'Price must be an integer';

    // Validate unified content
    service.content.forEach((item, index) => {
      if (!item.title.en) errors[`content_title_en_${index}`] = 'English title is required';
      if (!item.title.jp) errors[`content_title_jp_${index}`] = 'Japanese title is required';
      if (!item.body.en) errors[`content_body_en_${index}`] = 'English body is required';
      if (!item.body.jp) errors[`content_body_jp_${index}`] = 'Japanese body is required';
    });

    return errors;
  };

  const confirmEdit = () => {
    const errors = validateService(editService);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setEditConfirmModalOpen(true);
  };

  const handleConfirmEdit = () => {
    const token = getToken();

    const formattedEditService = {
      service_name: editService.service_name,
      service_type: editService.service_type,
      access: editService.access,
      title: {
        en: editService.title_en,
        jp: editService.title_ja,
      },
      description: {
        en: editService.description_en,
        jp: editService.description_ja,
      },
      content: editService.content,
      price: editService.price,
      url: editService.url,
    };

    axios.put(`${apiUrl}/data/services/${editService.id}`, formattedEditService, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setServices((prevServices) =>
          prevServices.map((service) =>
            service.id === editService.id ? { ...service, ...editService } : service
          )
        );
        setEditModalOpen(false);
        setEditConfirmModalOpen(false);
        setAlertMessage('Service updated successfully');
        setAlertSeverity('success');
      })
      .catch((error) => {
        console.error("Error updating service:", error);
        setAlertMessage('Error updating service');
        setAlertSeverity('error');
      });
  };

  const confirmDelete = () => {
    const token = getToken();

    axios.delete(`${apiUrl}/data/services`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { service_id: deleteServiceId }
    })
      .then(() => {
        setServices((prevServices) => prevServices.filter(service => service.id !== deleteServiceId));
        setDeleteModalOpen(false);
        setAlertMessage('Service deleted successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error deleting service:", error);
        setAlertMessage('Error deleting service');
        setAlertSeverity('error');
      });
  };

  const handleAddService = () => {
    
    const errors = validateService(newService);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setAddConfirmModalOpen(true);
    
  };

  const handleConfirmAddService = () => {
    const token = getToken();
  
    const formattedNewService = [{
      service_name: newService.service_name,
      service_type: newService.service_type,
      access: newService.access,
      title: {
        en: newService.title_en,
        jp: newService.title_ja,
      },
      description: {
        en: newService.description_en,
        jp: newService.description_ja,
      },
      content: newService.content,
      price: newService.price,
      url: newService.url,
    }];
  
    axios.post(`${apiUrl}/data/services`, formattedNewService, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        console.log('response:', response);
        setServices(response.data.services);  // Assuming the response contains the updated list of services
        setAddModalOpen(false);
        setAddConfirmModalOpen(false);
        setNewService({
          service_name: '', title_ja: '', description_ja: '', title_en: '',
          description_en: '', rating: 0, use_count: 0, price: 0, url: '',
          content: [], created_at: '', updated_at: ''
        });
        setAlertMessage('Service added successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error adding service:", error);
        setAlertMessage('Error adding service');
        setAlertSeverity('error');
      });
  };

  const handleViewContent = (service, editMode = false) => {
    setEditService({
      ...service,
      content: service.content || []
    });
    setCurrentContent(service.content || []);
    setContentEditMode(editMode);
    setContentModalOpen(true);
  };

  const handleSaveContent = () => {
    const errors = validateService({ ...editService, content: currentContent });
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setContentConfirmModalOpen(true);
  };

  const handleConfirmSaveContent = () => {
    const token = getToken();

    const formattedEditService = {
      service_name: editService.service_name,
      title: {
        en: editService.title_en,
        jp: editService.title_ja,
      },
      description: {
        en: editService.description_en,
        jp: editService.description_ja,
      },
      content: currentContent,
      price: editService.price,
      url: editService.url,
    };

    axios.put(`${apiUrl}/data/services/${editService.id}`, formattedEditService, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setServices((prevServices) =>
          prevServices.map((service) =>
            service.id === editService.id ? { ...service, ...editService, content: currentContent } : service
          )
        );
        setContentModalOpen(false);
        setContentConfirmModalOpen(false);
        setAlertMessage('Content updated successfully');
        setAlertSeverity('success');
      })
      .catch((error) => {
        console.error("Error updating content:", error);
        setAlertMessage('Error updating content');
        setAlertSeverity('error');
      });
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Service Management Panel</h1>
      {alertMessage && <Alert style={{ marginBottom: '10px' }} severity={alertSeverity}>{alertMessage}</Alert>}
      <TextField
        label="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <Button variant="contained" startIcon={<Add />} onClick={() => setAddModalOpen(true)}>
        Add Service
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '1rem', width: '100%', overflowX: 'auto', maxHeight: '70vh' }}>
        <Table style={{ width: '2000px' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }}>No.</TableCell>
              <TableCell>ID</TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'service_name'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('service_name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'service_type'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('service_type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('access')}
                >
                  Access
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>Title (JA)</TableCell>
              <TableCell style={{ width: '500px' }}>Description (JA)</TableCell>
              <TableCell style={{ width: '200px' }}>Title (EN)</TableCell>
              <TableCell style={{ width: '500px' }}>Description (EN)</TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'rating'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('rating')}
                >
                  Rating
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '50px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'use_count'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('use_count')}
                >
                  Use Count
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '50px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'price'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('price')}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>URL</TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'created_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('created_at')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'updated_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('updated_at')}
                >
                  Updated At
                </TableSortLabel>
              </TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service, index) => (
              <TableRow key={service.id}>
                <TableCell style={{ width: '50px' }}>{index + 1}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.id}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.service_name}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.service_type}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.access}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.title_ja}</TableCell>
                <TableCell style={{ width: '500px' }}>{service.description_ja}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.title_en}</TableCell>
                <TableCell style={{ width: '500px' }}>{service.description_en}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.rating}</TableCell>
                <TableCell style={{ width: '50px' }}>{service.use_count}</TableCell>
                <TableCell style={{ width: '50px' }}>{service.price}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.url}</TableCell>
                <TableCell style={{ width: '200px' }}>{new Date(service.created_at).toLocaleString()}</TableCell>
                <TableCell style={{ width: '200px' }}>{new Date(service.updated_at).toLocaleString()}</TableCell>
                <TableCell>
                  <Tooltip title="View/Edit Content">
                    <IconButton onClick={() => handleViewContent(service, true)}><Edit /></IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(service)}><Edit /></IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => { setDeleteServiceId(service.id); setDeleteModalOpen(true); }}><Delete /></IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Dialog open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Service</DialogTitle>
        <DialogContent>
          {['service_name', 'service_type', 'access', 'title_ja', 'description_ja', 'title_en', 'description_en', 'price'].map(field => (
            <TextField
              key={field}
              margin="dense"
              label={field}
              name={field}
              value={editService?.[field] || ''}
              onChange={handleEditChange}
              fullWidth
              multiline={['description_ja', 'description_en'].includes(field)}
              rows={['description_ja', 'description_en'].includes(field) ? 4 : 1} // Allow multiple lines for descriptions
              error={!!validationErrors[field]}
              helperText={validationErrors[field]}
            />
          ))}
          {editService && editService.content && (
            <ContentEditor
              content={editService.content}
              setContent={(content) => setEditService({ ...editService, content })}
              validationErrors={validationErrors}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmEdit}>
            Save Changes
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setEditModalOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Content Modal */}
      <Dialog open={isContentModalOpen} onClose={() => setContentModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{isContentEditMode ? 'Edit Content' : 'View Content'}</DialogTitle>
        <DialogContent>
          <ContentEditor
            content={currentContent}
            setContent={setCurrentContent}
            validationErrors={validationErrors}
          />
        </DialogContent>
        <DialogActions>
          {isContentEditMode && (
            <Button variant="contained" color="primary" onClick={handleSaveContent}>
              Save Changes
            </Button>
          )}
          <Button variant="outlined" color="secondary" onClick={() => setContentModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      

      {/* Add Service Modal */}
      <Dialog open={isAddModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent>
          {['service_name', 'service_type', 'access', 'title_ja', 'description_ja', 'title_en', 'description_en', 'price', 'url'].map(field => (
            <TextField
              key={field}
              margin="dense"
              label={field}
              name={field}
              value={newService[field]}
              onChange={(e) => setNewService({ ...newService, [e.target.name]: e.target.value })}
              fullWidth
              multiline={['description_ja', 'description_en'].includes(field)}
              rows={['description_ja', 'description_en'].includes(field) ? 4 : 1} // Allow multiple lines for descriptions
              error={!!validationErrors[field]}
              helperText={validationErrors[field]}
            />
          ))}
          <ContentEditor
            content={newService.content}
            setContent={(content) => setNewService({ ...newService, content })}
            validationErrors={validationErrors}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleAddService}>
            Add Service
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setAddModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Confirmation Modal */}
      <ModalWindow
        open={isAddConfirmModalOpen}
        handleClose={() => setAddConfirmModalOpen(false)}
        handleConfirm={handleConfirmAddService}
        title="Confirm Addition"
        description="Are you sure you want to add this service?"
        confirmText="Add"
        cancelText="Cancel"
      />

      {/* Edit Confirmation Modal */}
      <ModalWindow
        open={isEditConfirmModalOpen}
        handleClose={() => setEditConfirmModalOpen(false)}
        handleConfirm={handleConfirmEdit}
        title="Confirm Changes"
        description="Are you sure you want to save changes to this service?"
        confirmText="Save"
        cancelText="Cancel"
      />

      {/* Delete Confirmation Modal */}
      <ModalWindow
        open={isDeleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={confirmDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this service?"
        confirmText="Delete"
        cancelText="Cancel"
      />

      {/* Saving content changes Confirmation modal */}
      <ModalWindow
        open={isContentConfirmModalOpen}
        handleClose={() => setContentConfirmModalOpen(false)}
        handleConfirm={handleConfirmSaveContent}
        title="Confirm Changes"
        description="Are you sure you want to save changes to this content?"
        confirmText="Save"
        cancelText="Cancel"
      />

    </div>
  );
};

export default ServiceManagement;