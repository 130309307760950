import React, { useState } from 'react';
import { Box, Typography, LinearProgress, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { RemoveButton } from '../ReusableComponents';

export const ImageContainer = styled(({ isDragging, ...other }) => <Box {...other} />)(({ theme, isDragging }) => ({
  position: 'relative',
  width: '100%',
  height: '300px',
  overflow: 'hidden',
  borderRadius: '12px',
  border: theme.palette.mode === 'dark'
    ? '1px solid rgba(255, 255, 255, 0.1)'
    : '1px solid rgba(157, 78, 221, 0.2)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0, 0, 0, 0.9)'  // Increased shadow depth
    : '0 4px 20px rgba(0, 0, 0, 0.5)',
  transition: 'all 0.3s ease',
  background: theme.palette.mode === 'dark'
    ? 'rgba(26, 16, 37, 0.9)'  // Increased opacity for darker overlay
    : 'rgba(227, 187, 235, 0.4)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto', // Center horizontally
  [theme.breakpoints.down('sm')]: {
    height: '30vh', // 20% of the viewport height on small screens
  },
}));

export const DropZone = styled(({ isDragging, ...other }) => <Box {...other} />)(({ theme, isDragging }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  border: `2px dashed ${isDragging 
    ? theme.palette.mode === 'dark'
      ? '#9D4EDD' // Purple for dark mode
      : '#6B2E99' // Darker purple for light mode
    : 'transparent'}`,
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  backgroundColor: isDragging 
    ? theme.palette.mode === 'dark'
      ? 'rgba(157, 78, 221, 0.4)' // Increased opacity for dark mode
      : 'rgba(157, 78, 221, 0.2)' // Increased opacity for light mode
    : 'transparent',
  '&:hover': {  
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(157, 78, 221, 0.3)' // Increased hover opacity for dark
      : 'rgba(157, 78, 221, 0.15)', // Increased hover opacity for light
  }
}));

export const DragOverlay = styled(({ isDragging, ...other }) => <Box {...other} />)(({ theme, isDragging }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(26, 16, 37, 0.8)'
    : 'rgba(255, 255, 255, 0.8)',
  border: theme.palette.mode === 'dark'
    ? `2px dashed #9D4EDD`  // Light purple for dark mode
    : `2px dashed #6B2E99`, // Dark purple for light mode
  borderRadius: '12px',
  opacity: isDragging ? 1 : 0,
  pointerEvents: 'none',
  transition: 'all 0.3s ease',
  zIndex: 2,
  color: theme.palette.mode === 'dark'
    ? '#9D4EDD'  // Purple for dark mode
    : '#6B2E99', // Darker purple for light mode
}));

export const ProgressOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '12px',
  color: theme.palette.mode === 'dark'
    ? '#9D4EDD'  // Purple for dark mode
    : '#6B2E99', // Darker purple for light mode
}));

const ImageUploadFrame = ({
  originalImage,
  onFileSelect,
  loading,
  translations: t,
  inputRef,
  progress,
  step,
  setError, 
}) => {
  const IMAGE_SIZE_LIMIT = 4 * 1024 * 1024; // 4MB in bytes
  const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/tiff', 'image/webp', 'image/heic'];
  const [isDragging, setIsDragging] = useState(false);

  const validateFile = (file) => {
    if (!file) return false;
    
    // Check file type
    if (!ALLOWED_TYPES.includes(file.type)) {
      setError(t.errorFileType);
      return false;
    }

    // Check file size
    if (file.size > IMAGE_SIZE_LIMIT) {
      setError(t.errorExceededSize);
      return false;
    }

    return true;
  };

  const convertToJPEG = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        const img = new window.Image(); // Use window.Image to avoid conflict
        img.onload = function() {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          canvas.toBlob(function(blob) {
            const jpegFile = new File([blob], file.name.replace(/\.[^/.]+$/, "") + ".jpeg", { type: 'image/jpeg' });
            resolve(jpegFile);
          }, 'image/jpeg');
        };
        img.src = event.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileSelect = async (file) => {
    if (file instanceof File) {
      if (validateFile(file)) {
        setError(null);
        const jpegFile = await convertToJPEG(file);
        onFileSelect(jpegFile);
      }
    }
  };

  const handleInputChange = (e) => {
    const file = e?.target?.files?.[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleDragEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    handleDragEvents(e);
    setIsDragging(false);
    const file = e.dataTransfer?.files?.[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleRemoveImage = () => {
    onFileSelect(null);
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={handleInputChange}
        accept="image/*"
      />
      
      <ImageContainer 
        isDragging={isDragging}
        onDragEnter={(e) => {
          handleDragEvents(e);
          setIsDragging(true);
        }}
        onDragLeave={(e) => {
          handleDragEvents(e);
          setIsDragging(false);
        }}
        onDragOver={handleDragEvents}
        onDrop={handleDrop}
      >
        {!originalImage ? (
          <DropZone
            isDragging={isDragging}
            onClick={(e) => { inputRef.current?.click(); }}
          >
            {!isDragging && (
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center',
                mb: 2 
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  height: '50px', 
                  mx: 'auto' 
                }}>
                  <CloudUploadIcon 

                    sx={{ 
                      fontSize: 48, 
                      color: '#4A0072',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        fontSize: 56, // Increase size on hover
                        color: '#8A2BE2' // Change color on hover
                      }
                    }} 
                  />
                </Box>
                
                <Box sx={{ textAlign: 'center', maxWidth: '300px', mx: 'auto' }}>
                  <Typography 
                    variant="h6" 
                    color="textSecondary" 
                    sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
                  >
                    {t.dragDropText}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ mt: 2, color: 'red', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                  >
                    {t.supportImageType}
                  </Typography>
                </Box>
              </Box>
            )}
          </DropZone>
        ) : (
          <>
            <img 
              src={URL.createObjectURL(originalImage)} 
              alt="Uploaded" 
              style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
            />
            { !loading && (
              <RemoveButton onClick={handleRemoveImage}>
                <CloseIcon />
              </RemoveButton>
            )}
          </>
        )}
        <DragOverlay isDragging={isDragging}>
          <CloudUploadIcon sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h6">
            {t.dropText}
          </Typography>
        </DragOverlay>

        {loading && originalImage && (
          <ProgressOverlay>
            <CircularProgress size={48} sx={{ mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              {step === 0 ? t.uploadingText : step === 1 ? t.processingText : t.downloadingText}
            </Typography>
            {progress !== null && (
              <LinearProgress variant="determinate" value={progress} sx={{ width: '30%', mb: 2 }} />
            )}
          </ProgressOverlay>
        )}
      </ImageContainer>
    </>
  );
};

export default ImageUploadFrame;