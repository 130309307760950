import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Box, TextField, IconButton, Paper, CircularProgress, Fade } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import ChatMessage from './ChatMessage';
import LoadingDots from './LoadingDots';
import { useParams, useNavigate } from 'react-router-dom';
import { createRagApi } from './RAGapi';
import { AuthContext } from '../../contexts/AuthContext';
import { flushSync } from 'react-dom';

// ---------------- Enhanced Styled Components ----------------

const ConversationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 100px)',
  position: 'relative',
  backgroundColor: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.background.default, 0.95)
    : alpha(theme.palette.background.paper, 0.95),
}));

// Increase bottom padding to theme.spacing(30)
const MessageList = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(30),
  minHeight: '300px',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[400],
    borderRadius: '10px',
    '&:hover': {
      background: theme.palette.grey[500],
    },
  },
}));

const FloatingInputContainer = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'calc(100% - 48px)',
  maxWidth: '800px',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.9)
    : alpha(theme.palette.background.paper, 0.95),
  backdropFilter: 'blur(10px)',
  boxShadow: theme.palette.mode === 'dark'
    ? `0 4px 20px ${alpha(theme.palette.common.black, 0.3)}`
    : `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
  zIndex: 100,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.white, 0.05)
      : alpha(theme.palette.common.white, 0.9),
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const FilePreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: theme.spacing(1),
  fontSize: '0.875rem',
}));

// A container for the top loading indicator.
const TopLoadingContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

// ---------------- Helper Functions ----------------

// Checks if the container’s scroll position is near the top (within 150px)
const isCloseToTop = (element, threshold = 150) => {
  if (!element) return false;
  return element.scrollTop <= threshold;
};

// Helper to scroll to the bottom of the chat container
const scrollToBottom = (container) => {
  if (container) {
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth'
    });
  }
};

// ---------------- ChatConversation Component ----------------

export default function ChatConversation() {
  const { projectId, chatId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = React.useMemo(() => createRagApi(apiUrl, isAuthenticated), [apiUrl, isAuthenticated]);

  // Chat state.
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);

  // Pagination state.
  // Optimal page size set to 20 for a good balance between fewer API calls and UI responsiveness.
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  // Loading state.
  const [isLoading, setIsLoading] = useState(false);

  // Refs for managing scrolling, chat identity, and IME composition.
  const scrollableDivRef = useRef(null);
  const isInitialLoadRef = useRef(true);
  const isComposingRef = useRef(false);
  const isFetchingRef = useRef(false);
  const currentPageRef = useRef(1);
  const activeChatRef = useRef(chatId);

  // ---------------- Reset & Initial Load on Chat Change ----------------
  // Modify the initial load effect
  useEffect(() => {
    const resetState = () => {
      isInitialLoadRef.current = true;
      isFetchingRef.current = false;
      currentPageRef.current = 1;
      activeChatRef.current = chatId;

      setChatHistory([]);
      setCurrentPage(1);
      setHasMore(true);
      setMessage('');
      setFile(null);
      setIsStreaming(false);
      setIsLoading(false);
    };

    const loadChat = async () => {
      if (activeChatRef.current !== chatId) return;

      try {
        setIsLoading(true);
        const response = await api.getChatHistory(projectId, chatId, 1, pageSize);
        if (activeChatRef.current !== chatId) return;
      
        const mapped = response.data.messages.map((msg) => ({
          ...msg,
          content: msg.message,
          timestamp: msg.created_at,  // Use created_at directly
          id: msg.id || `${Date.now()}-${Math.random()}`,
        }));

        setChatHistory(mapped);
        setHasMore(mapped.length >= pageSize);

        // Ensure scroll to bottom after loading chat history
        requestAnimationFrame(() => {
          if (scrollableDivRef.current) {
            scrollToBottom(scrollableDivRef.current);
          }
        });
      } catch (error) {
        console.error("Error fetching initial chat history:", error);
      } finally {
        if (activeChatRef.current === chatId) {
          setIsLoading(false);
        }
      }
    };

    // Reset state on mount and chat change
    resetState();

    if (projectId && chatId) {
      loadChat();
    }

    // Add window reload event listener
    const handleBeforeUnload = () => {
      resetState();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      activeChatRef.current = null;
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chatId, projectId, api, pageSize]);


  // ---------------- Fetch Older Messages ----------------
  const fetchMoreMessages = useCallback( async () => {
    if (!scrollableDivRef.current || isFetchingRef.current || !hasMore) return;
    if (activeChatRef.current !== chatId) return;
    
    const container = scrollableDivRef.current;
    if (!isCloseToTop(container)) return;

    const oldScrollTop = container.scrollTop;
    const oldScrollHeight = container.scrollHeight;

    isFetchingRef.current = true;
    setIsLoading(true);
    const nextPage = currentPageRef.current + 1;

    try {
      const response = await api.getChatHistory(projectId, chatId, nextPage, pageSize);
      const olderMessages = response.data.messages.map((msg) => ({
        ...msg,
        content: msg.message,
        timestamp: msg.created_at,  // Use created_at directly
        id: msg.id || `${Date.now()}-${Math.random()}`,
      }));

      if (olderMessages.length > 0) {
        setChatHistory(prev => [...olderMessages, ...prev]);
        setCurrentPage(nextPage);
        currentPageRef.current = nextPage;
        
        if (olderMessages.length < pageSize) {
          setHasMore(false);
        }

        requestAnimationFrame(() => {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop = oldScrollTop + (newScrollHeight - oldScrollHeight);
        });
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching older messages:", error);
      setHasMore(false);
    } finally {
      isFetchingRef.current = false;
      setIsLoading(false);
    }
  }, [api, chatId, hasMore, projectId]);

  // ---------------- Scroll Listener ----------------
  useEffect(() => {
    const container = scrollableDivRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (isCloseToTop(container) && !isFetchingRef.current && hasMore) {
        fetchMoreMessages();
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [hasMore, chatId, fetchMoreMessages]);

  // ---------------- Auto-Scroll for New Messages ----------------
  useEffect(() => {
    const container = scrollableDivRef.current;
    if (!container) return;

    // Auto-scroll if near the bottom
    const shouldAutoScroll =
      container.scrollHeight - container.scrollTop - container.clientHeight < 100;
    if (shouldAutoScroll) {
      setTimeout(() => {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: isInitialLoadRef.current ? 'auto' : 'smooth'
        });
        isInitialLoadRef.current = false;
      }, 0);
    }
  }, [chatHistory]);

  // ---------------- Handle AI Streaming Response ----------------
  const handleAIResponse = useCallback((responseText) => {
    setChatHistory((prev) => {
      const last = prev[prev.length - 1];
      if (last && last.sender.toLowerCase() === 'ai') {
        return [...prev.slice(0, -1), { ...last, content: responseText }];
      }
      return [...prev, { sender: 'AI', content: responseText, timestamp: Date.now(), id: `ai-${Date.now()}` }];
    });
  }, []);

  // ---------------- Send Message & Process AI Streaming ----------------
  // Modify handleSendMessage to ensure scroll to bottom
  const handleSendMessage = async () => {
    if (!message.trim() || activeChatRef.current !== chatId) return;

    const currentMessage = message;
    setMessage('');

    const userMessage = {
      sender: 'user',
      content: currentMessage,
      timestamp: Date.now(),
      id: `user-${Date.now()}`,
    };

    setChatHistory(prev => [...prev, userMessage]);

    // Scroll to bottom immediately after adding user message
    setTimeout(() => {
      if (scrollableDivRef.current) {
        scrollToBottom(scrollableDivRef.current);
      }
    }, 0);

    try {
      flushSync(() => {
        setIsStreaming(true);
      });
      const response = await api.askAI(projectId, currentMessage, file, chatId);
      const newChatId = response.headers.get('x-new-chat-id');
      if (newChatId) {
        navigate(`/rag-system/chat/${projectId}/${newChatId}`, { replace: true });
      }

      let aiResponse = '';
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let firstChunkReceived = false;

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        aiResponse += decoder.decode(value);
        if (!firstChunkReceived) {
          firstChunkReceived = true;
          flushSync(() => {
            setIsStreaming(false);
          });
        }
        handleAIResponse(aiResponse);

        // Scroll to bottom after each chunk
        requestAnimationFrame(() => {
          if (scrollableDivRef.current) {
            scrollToBottom(scrollableDivRef.current);
          }
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setChatHistory(prev => prev.filter(msg => msg.id !== userMessage.id));
    } finally {
      flushSync(() => {
        setIsStreaming(false);
      });
      setMessage('');
      setFile(null);

      // Final scroll to bottom
      requestAnimationFrame(() => {
        if (scrollableDivRef.current) {
          scrollToBottom(scrollableDivRef.current);
        }
      });
    }
  };

  // ---------------- Handle Key Press for Sending Message ----------------
  const handleKeyDown = (e) => {
    if (isComposingRef.current) return;
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey || !e.shiftKey)) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // ---------------- Composition Handlers for IME ----------------
  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  return (
    <ConversationContainer>
      <MessageList id="scrollableDiv" ref={scrollableDivRef}>
        {/* Display a top loading indicator or end-of-list message only if not on initial page */}
        {currentPage > 1 && isLoading && (
          <Fade in={true} timeout={500}>
            <TopLoadingContainer sx={{ position: 'static' }}>
              <CircularProgress size={20} />
              <Box ml={1}>Loading more messages...</Box>
            </TopLoadingContainer>
          </Fade>
        )}
        {currentPage > 1 && !hasMore && (
          <Fade in={true} timeout={500}>
            <Box sx={{ textAlign: 'center', py: 1, color: 'text.secondary' }}>
              No more message
            </Box>
          </Fade>
        )}
        {/* Render chat messages */}
        {chatHistory.map((msg) => (
          <ChatMessage
            key={msg.id || `${msg.timestamp}-${msg.sender}`}
            content={msg.content}
            isUser={msg.sender.toLowerCase() === 'user'}
            timestamp={msg.timestamp}
          />
        ))}
        {isStreaming && (
          <ChatMessage isUser={false} loading>
            <LoadingDots />
          </ChatMessage>
        )}
      </MessageList>

      <FloatingInputContainer elevation={0}>
        <StyledTextField
          placeholder="Type your message..."
          fullWidth
          multiline
          maxRows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          disabled={isStreaming}
          sx={{ mb: file ? 1 : 0 }}
        />
        
        {file && (
          <FilePreview>
            <AttachFileRoundedIcon fontSize="small" />
            <span>{file.name}</span>
            <IconButton size="small" onClick={() => setFile(null)}>
              ×
            </IconButton>
          </FilePreview>
        )}

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          gap: 1, 
          mt: 1 
        }}>
          <IconButton
            component="label"
            color="primary"
            disabled={isStreaming}
            sx={{ 
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
              }
            }}
          >
            <AttachFileRoundedIcon />
            <input type="file" hidden onChange={(e) => setFile(e.target.files[0])} />
          </IconButton>
          
          <IconButton
            color="primary"
            onClick={handleSendMessage}
            disabled={(!message.trim() && !file) || isStreaming}
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
              }
            }}
          >
            <SendRoundedIcon />
          </IconButton>
        </Box>
      </FloatingInputContainer>
    </ConversationContainer>
  );
}
