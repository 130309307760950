// /components/RAGComponents/RAGapi.js
import axios from 'axios';

// Helper function to get the token from localStorage
const getToken = (isAuthenticated) =>
  isAuthenticated ? localStorage.getItem('token') : localStorage.getItem('guestToken');

export const createRagApi = (apiUrl, isAuthenticated, setDownloadProgress) => {
  return {
    // --- Project Endpoints ---
    async getProjects() {
      return axios.get(`${apiUrl}/rag/projects`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    async createProject(name, description) {
      return axios.post(
        `${apiUrl}/rag/projects`,
        { name, description },
        { headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` } }
      );
    },
    async updateProject(projectId, name, description) {
      return axios.put(
        `${apiUrl}/rag/projects/${projectId}`,
        { name, description },
        { headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` } }
      );
    },
    async deleteProject(projectId) {
      return axios.delete(`${apiUrl}/rag/projects/${projectId}`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    async getChatSessions(projectId) {
      return axios.get(`${apiUrl}/rag/projects/${projectId}/chats`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    // --- File Endpoints ---
    async uploadMultipleFiles(projectId, files, backgroundTasks) {
      const formData = new FormData();
      files.forEach(file => {
        formData.append('files', file);
      });
      return axios.post(`${apiUrl}/rag/projects/${projectId}/files/upload-multiple`, formData, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` },
        onUploadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);
          backgroundTasks?.(progress);
        }
      });
    },
    async uploadFile(projectId, file, backgroundTasks) {
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(`${apiUrl}/rag/projects/${projectId}/files/upload`, formData, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` },
        onUploadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);
          backgroundTasks?.(progress);
        }
      });
    },
    async getProjectFiles(projectId) {
      return axios.get(`${apiUrl}/rag/projects/${projectId}/files`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    async pollFileStatus(projectId) {
      return axios.get(`${apiUrl}/rag/projects/${projectId}/files/status`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    async deleteFile(projectId, fileId) {
      return axios.delete(`${apiUrl}/rag/projects/${projectId}/files/${fileId}`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    // --- Chat Endpoints ---
    async askAI(projectId, message, file, chatId = null) {
      const formData = new FormData();
      formData.append('message', message);
      if (file) {
          formData.append('file', file, file.name);
      }
  
      const token = getToken(isAuthenticated);
      const url = `${apiUrl}/rag/projects/${projectId}/chats/ask?chat_id=${chatId || ''}`;
  
      // Use fetch() instead of axios to handle streaming
      const response = await fetch(url, {
          method: 'POST',
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
      });
  
      if (!response.body) throw new Error('No response body');
  
      return response; // Return response for streaming handling in handleSendMessage()
    },
    async getChatHistory(projectId, chatId, page = 1, pageSize = 10) {
      return axios.get(`${apiUrl}/rag/projects/${projectId}/chats/${chatId}/history`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` },
        params: { page, page_size: pageSize }
      });
    },
    // --- New Chat Management Endpoints ---
    async updateProjectChat(projectId, chatId, payload) {
      return axios.put(`${apiUrl}/rag/projects/${projectId}/chats/${chatId}`, payload, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    async deleteProjectChat(projectId, chatId) {
      return axios.delete(`${apiUrl}/rag/projects/${projectId}/chats/${chatId}`, {
        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
      });
    },
    async createChatSession(projectId, chatName) {
        return axios.post(
          `${apiUrl}/rag/projects/${projectId}/chats`,
          { chat_name: chatName },
          {
            headers: {
              'Authorization': `Bearer ${getToken(isAuthenticated)}`,
            },
          }
        );
      }
  };
};
