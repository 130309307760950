// /components/RAGComponents/ChatRow.js

import React from 'react';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const ChatRowContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})(({ theme, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(4),
  backgroundColor: isSelected ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: isSelected
      ? theme.palette.action.selected
      : theme.palette.action.hover,
  },
}));

const ChatNameText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'sidebarMode',
})(({ theme, sidebarMode }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: 0,
  flex: 1,
  fontSize: sidebarMode ? '0.875rem' : '1rem',
}));

const ChatRow = ({
  chat,
  onMenuOpen,
  onSelect,
  sidebarMode = false, // new prop
  isSelected = false,
}) => {
  return (
    <ChatRowContainer isSelected={isSelected} onClick={onSelect}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onMenuOpen(e);
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0, flex: 1 }}>
        <ChatBubbleIcon sx={{ mr: 1 }} />
        <ChatNameText sidebarMode={sidebarMode}>
          {chat.chat_name}
        </ChatNameText>
      </Box>
    </ChatRowContainer>
  );
};

export default ChatRow;
